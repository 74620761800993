export const isWeChat = () => {
	var ua = window.navigator.userAgent.toLowerCase();
	// eslint-disable-next-line eqeqeq
	return ua.match(/MicroMessenger/i) == 'micromessenger';
};

export const getDeviceInfo = () => {
	// 获取设备用户代理字符串
	const userAgent = navigator.userAgent;
	const devcieInfo = {
		model: '',
		version: '',
	};
	if (userAgent.indexOf('iPhone') > -1) {
		// 判断用户代理字符串中是否包含'iPhone'，若包含则表示是iPhone手机
		// 解析出iPhone的型号和版本信息
		const regex = /iPhone\s(OS\s)?(\d[\d_]+)+/g;
		const matches = regex.exec(userAgent);
		if (matches) {
			const model = 'iPhone';
			const version = matches[2].replace(/_/g, '.');
			// console.log('Device model: ' + model)
			// console.log('iOS version: ' + version)
			devcieInfo.model = model;
			devcieInfo.version = version;
		}
	} else if (userAgent.indexOf('Android') > -1) {
		// 判断用户代理字符串中是否包含'Android'，若包含则表示是Android手机
		// 解析出Android的型号和版本信息
		const regex = /Android\s(\d[\d.]+)+;/g;
		const matches = regex.exec(userAgent);
		if (matches) {
			const version = matches[1];
			// 判断设备型号
			const model = 'android';
			// if (userAgent.indexOf('Mi') > -1) {
			//   model = 'Xiaomi'
			// } else if (userAgent.indexOf('HUAWEI') > -1) {
			//   model = 'HUAWEI'
			// } else if (userAgent.indexOf('OPPO') > -1) {
			//   model = 'OPPO'
			// } else if (userAgent.indexOf('vivo') > -1) {
			//   model = 'vivo'
			// } else {
			//   model = 'Other android'
			// }
			// console.log('Device model: ' + model)
			// console.log('Android version: ' + version)
			devcieInfo.model = model;
			devcieInfo.version = version;
		}
	}
	return devcieInfo;
};

export const getQueryParams = name => {
	var url_string = location.href;
	var params = url_string.split('?')[1]; // 获取?号后面的参数 name=mick&age=20
	if (!params) return null; // 如果无参，返回null
	var vars = params.split('&'); // 将参数分割成数组 ['name=mick', 'age=20']
	var query_string = {};
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split('='); // 将参数的key和值进行分割成 ['name', 'mick']
		var key = decodeURIComponent(pair[0]); // 参数key
		var value = decodeURIComponent(pair[1]); // 参数值
		query_string[key] = value;
	}
	return query_string;
};

// 新增或者替换某个query参数
export const historyReplaceTab = ({ name, value }) => {
	const stateObj = history.state || {};
	const queryObj = {};
	let url = location.origin + location.pathname;
	if (location.hash) {
		url += location.hash.split('?')[0];
	}
	const arr = location.href.split('?');
	// 获取url自带的参数
	if (arr[1]) {
		const aKeyValue = arr[1].split('&');
		aKeyValue.forEach(ele => {
			const oKeyValue = ele.split('=');
			if (oKeyValue[1] && oKeyValue[1] !== 'undefined') {
				stateObj[oKeyValue[0]] = oKeyValue[1];
				queryObj[oKeyValue[0]] = oKeyValue[1];
			}
		});
	}
	queryObj[name] = value;
	let query = '';
	Object.keys(queryObj).forEach(key => {
		if (queryObj[key]) {
			// 抛弃空值
			if (query) {
				query += `&${key}=${queryObj[key]}`;
			} else {
				query += `?${key}=${queryObj[key]}`;
			}
		}
	});

	history.replaceState(stateObj, history.title, url + query);
};

export const createId = () => {
	return new Date().getTime() + Math.random().toString(36).substring(2);
};

// 判断项目类型// 1：营养，2：养生
export const getProjectType = (projectId) => {
  if (process.env.VUE_APP_MODE === 'production') {
    if ([2, 8, 9, 10].includes(projectId)) {
      // 营养项目
      return {
        type: 1
      }
    } else if ([1, 3, 4, 5, 7, 11, 12].includes(projectId)) {
      // 养生
      return {
        type: 2
      }
    }
  } else {
    if ([7, 9, 10].includes(projectId)) {
      // 营养项目
      return {
        type: 1
      }
    } else if ([1, 2, 3, 4, 8, 11, 12].includes(projectId)) {
      // 养生
      return {
        type: 2
      }
    }
  }
}
