export default [
	{
		path: '/offiaccount/healthCard',
		name: 'healthCard',
		component: () => import(/* healthCard */ './index.vue'),
		meta: {
			title: '智省事',
			// keepAlive: true  //是否设置为缓存数据页面
		},
		children: [
			{
				path: 'index',
				name: 'healthCardIndex',
				component: () => import(/* healthCardIndex */ './pages/index.vue'),
				meta: {
					title: '智省事',
					auth: true,
					// keepAlive: true  //是否设置为缓存数据页面
				}
			},
			{
				path: 'active',
				name: 'healthCardActive',
				component: () => import(/* healthCardActive */ './pages/active.vue'),
				meta: {
					title: '智省事',
					auth: true,
					// keepAlive: true  //是否设置为缓存数据页面
				}
			},
			{
				path: 'remind',
				name: 'healthCardRemind',
				component: () => import(/* healthCardActive */ './pages/remind.vue'),
				meta: {
					title: '智省事',
					// auth: true,
					// keepAlive: true  //是否设置为缓存数据页面
				}
			}
		]
	}
]
