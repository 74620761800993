import request from '@/utils/request';

export const LoginOrRegist = data => {
	// avatarAddress
	// createTime
	// deviceCode
	// deviceOs
	// enrollIp
	// id
	// openid
	// phone
	// status
	// studentName
	// unionid
	// updateTime
	return request({
		url: `/hm/userInfo/login?unionId=${data.unionid}`,
		method: 'post',
		// type: 'json', // formDate类型数据值为 'form'
		// type: 'form',
		// data,
	});
};

export const getUserInfo = data => {
	return request({
		url: '/user/student/info',
		method: 'get',
		type: 'json', // formDate类型数据值为 'form'
		// type: 'form',
		params: data,
	});
};
