import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

let routes = [
	{
		path: '/needWechat',
		name: 'needWechat',
		component: () => import(/* needWechat*/ '@/views/needWechat.vue'),
		meta: { title: '提示' },
	},
	{
		path: '/authlogin',
		name: 'AuthLogin',
		component: () => import(/* AuthLogin*/ '@/views/AuthLogin.vue'),
		meta: { title: '微信登录' },
	},
];

/**
 * require.context 实现流程自动化
 * @description 自动遍历业务侧路由
 * @param { String } directory - 读取的文件路径
 * @param { Boolean } useSubdirectories - 是否遍历文件子目录
 * @param { RegExp } regExp - 匹配文件的正则
 */
const routerContext = require.context('../views', true, /_router\.js$/);
routerContext.keys().forEach(route => {
	const routerModule = routerContext(route);
	/**
	 * 兼容 import export 和 require module.export 两种规范
	 */
	routes = [...routes, ...(routerModule.default || routerModule)];
});

const router = new VueRouter({
	mode: 'history',
	routes,
});
router.beforeEach((to, from, next) => {
	if (/\?.*=.*#\//.test(location.href)) {
		// 微信回调后hash路由#导致code获取失败问题
		let search = location.search.substring(0, location.search.length);
		let hash = '';
		if (location.hash.includes('?')) {
			search = search.replace('?', '&');
		}
		hash = '/' + location.hash + search;
		history.replaceState(null, '', ('/offiaccount' + hash));
		next({ path: hash.substring(2, hash.length) });
	} else {
		next();
	}
});

export default router;
