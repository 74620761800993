import Vue from 'vue';
import Vuex from 'vuex';
import { LoginOrRegist, getUserInfo } from '@/api/login';
import { getDeviceInfo } from '@/utils/index';
import Cookies from 'js-cookie';

Vue.use(Vuex);

const userStateObj = {
	isGetting: false,
	resolveFns: [],
};

export default new Vuex.Store({
	state: {
		token: '',
		userInfo: {},
		queryInfo: {},
		formalPermission: false,
    highPermission: false,
	},
	getters: {
		userInfo(state) {
			return { ...state.userInfo };
		},
	},
	mutations: {
		setFormalPermission(state, data) {
			state.formalPermission = data
		},
		setHighPermission(state, data) {
			state.highPermission = data
		},
		setToken(state, data) {
			if (data) {
				state.token = data;
			}
			localStorage.setItem('token', state.token)
		},
		setQueryInfo(state, data) {
			if (data && Object.prototype.toString(data) === '[object Object]') {
				Object.assign(state.queryInfo, data);
			}
		},
		setUserInfo(state, data) {
			Object.assign(state.userInfo, {
				...data,
			});
			localStorage.setItem('studentInfo', JSON.stringify(state.userInfo))
			let domain = '.jkqingman.com';
			if (process.env.VUE_APP_MODE !== 'production') {
				domain = '.jkqianmei.com';
			}
			Cookies.set('studentInfo', JSON.stringify(state.userInfo), {
				expires: 365, // 天
				domain: domain,
			});
		},
	},
	actions: {
		getUserInfo({ commit, state, dispatch }, unionid) {
			const userInfo = state.userInfo;
			return new Promise((resolve, reject) => {
				if (userStateObj.isGetting) {
					userStateObj.resolveFns.push(resolve);
					return;
				} else {
					userStateObj.isGetting = true;
				}
				const selfGetUserInfo = () => {
					// 获取信息
					getUserInfo()
						.then(res => {
							commit('setUserInfo', res);
							userStateObj.isGetting = false;
							resolve(res);
							// 回调逻辑
							if (userStateObj.resolveFns.length) {
								userStateObj.resolveFns.forEach(fn => {
									fn(res);
								});
								userStateObj.resolveFns = [];
							}
						})
						.catch(err => {
							reject(err);
						});
				};
				if (unionid) {
					// 只有wxlogin组件会走一次，每次网页这里只会走一次
					// 更新信息，并获取token
					const devcieInfo = getDeviceInfo();
					LoginOrRegist({
						unionid: unionid,
					}).then(res => {
						let domain = '.zhishengshi.dyhz.com';
						if (process.env.VUE_APP_MODE !== 'production') {
							domain = '.jkqianmei.com';
						}
						Cookies.set('h5token', res, {
							expires: 365, // 天
							domain: domain,
						});
						commit('setToken', {
							token: res,
						});
						// selfGetUserInfo();
					});
				} else {
					// selfGetUserInfo();
				}
			});
		},
	},
	modules: {},
});
